import React from 'react'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import OrderPickupButton from './OrderPickupButton'
import { hero, heroText, heroTextbg, mobileHero } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          column
          center
          h="90VH"
          w="100%"
          image={`url(${mobileHero}) center / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
        >
          <CFView relative row justifyEnd pb="30px">
            <CFView bg="rgba(255,255,255,.6)" column center pv="30px">
              <CFView
                bold
                raleway
                fontSize="55px"
                textCenter
                color="black"
                mb="30px"
                ph="15px"
              >
                NOW TAKING ONLINE ORDERS
              </CFView>
              <CFView row justifyBetween ml="10px">
                <OrderPickupButton />
                {/* <OrderDeliveryButton /> */}
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="620px"
          w="100%"
          image={`url(${hero}) center / cover no-repeat`}
          boxShadow="0 2px 5px rgba(0,0,0,.5)"
        >
          <CFView row justifyEnd pb="30px">
            <CFView
              pt="50px"
              image={`url(${heroTextbg}) center / cover no-repeat`}
              minWidth="450px"
              column
              center
              w="30%"
              h="620px"
            >
              <CFView column center ph="40px" relative>
                <CFImage
                  src={heroText}
                  w="100%"
                  h="80%"
                  maxWidth="500px"
                  alt="About"
                />
                <CFView relative row justifyBetween bottom="16%">
                  <OrderPickupButton />
                  {/* <OrderDeliveryButton /> */}
                </CFView>
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
