import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import {
  CFView,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  contactbg,
  logo,
  logoTitle,
  viewMenu,
  menu1,
  menu2,
  menu3,
  menu4,
  menu5,
  menu6,
  menu7,
  menu8,
  menu9,
  menu10,
  menu11,
  menu12,
} from 'images'

const orderingUrl =
  process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? 'https://demo.codefusion.tech/restaurants/ebisu/locations/ebisuLocation1'
    : 'http://order.codefusion.tech/restaurants/FJYugHJSTCDeg6F7Qxxw/locations/sEMCSqocWBFst8heGrFk'

const images = [
  {
    src: menu1,
  },
  {
    src: menu2,
  },
  {
    src: menu3,
  },
  {
    src: menu4,
  },
  {
    src: menu5,
  },
  {
    src: menu6,
  },
  {
    src: menu7,
  },
  {
    src: menu8,
  },
  {
    src: menu9,
  },
  {
    src: menu10,
  },
  {
    src: menu11,
  },
  {
    src: menu12,
  },
]

export default () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <MobileScreen>
        <CFView row justifyAround h="100px" bg="black">
          <CFView row center alignCenter>
            <CFImage h="80px" mt="10px" src={logo} alt="Sushi Asakusa Logo" />
            {/* <CFImage
              h="35px"
              ml="15px"
              src={logoTitle}
              alt="Sushi Asakusa Logo Title"
            /> */}
          </CFView>
          {/* <CFView mt="4px" onClick={() => setShowModal(true)} hover>
            <CFImage
              h="50px"
              src={viewMenu}
              alt="Sushi Asakusa View Menu Button"
            />
          </CFView> */}
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          center
          h="70px"
          bg="black"
          boxShadow="2px 2px 2px rgba(0,0,0,.5)"
        >
          <CFView w="100%" maxWidth="1400px" relative row justifyEnd>
            <CFView row justifyStart absolute left="35px" zIndex={1}>
              <CFImage
                h="130px"
                mt="10px"
                src={logo}
                alt="Sushi Asakusa Logo"
              />
              {/* <CFImage
                h="50px"
                mt="7px"
                src={logoTitle}
                alt="Sushi Asakusa Logo Title"
              /> */}
            </CFView>
            <CFView mt="8px" mr="25px" hover>
              <CFLink href={orderingUrl}>
                <CFImage
                  h="60px"
                  src={viewMenu}
                  alt="Sushi Asakusa View Menu Button"
                />
              </CFLink>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
      <ModalGateway>
        {showModal ? (
          <Modal onClose={() => setShowModal(false)}>
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}
