import React from 'react'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import { orderPickupButton } from 'images'

const orderingUrl =
  process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? 'https://demo.codefusion.tech/restaurants/ebisu/locations/ebisuLocation1'
    : 'http://order.codefusion.tech/restaurants/FJYugHJSTCDeg6F7Qxxw/locations/sEMCSqocWBFst8heGrFk'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <a href={orderingUrl}>
          <CFView hover>
            <CFImage src={orderPickupButton} maxWidth="300px" alt="About" />
          </CFView>
        </a>
      </MobileScreen>
      <DefaultScreen>
        <a href={orderingUrl}>
          <CFView hover>
            <CFImage src={orderPickupButton} w="300px" alt="About" />
          </CFView>
        </a>
      </DefaultScreen>
    </CFView>
  )
}
