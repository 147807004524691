import React from 'react'
import {
  CFImage,
  CFLink,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { yelp, facebook, zomato } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mv="20px" column justifyStart>
          <CFView h2 futura bold color="rgb(149, 123, 96)" mb="20px">
            SOCIAL MEDIA
          </CFView>
          <CFView row center white bold>
            <CFLink href="https://www.facebook.com/pages/Asakusa/318447008276576">
              <CFImage
                w="45px"
                pr="10px"
                src={facebook}
                alt="Sushi Asakusa Facebook"
              />
            </CFLink>
            <CFLink href="https://www.yelp.ca/biz/asakusa-langley?osq=asakusa">
              <CFImage w="45px" pr="10px" src={yelp} alt="Sushi Asakusa Yelp" />
            </CFLink>
            <CFLink href="https://www.zomato.com/vancouver/asakusa-japanese-walnut-grove">
              <CFImage
                w="45px"
                pr="10px"
                src={zomato}
                alt="Sushi Asakusa Zomato"
              />
            </CFLink>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView mb="13px" mt="20px" column justifyStart>
          <CFView h2 futura bold color="rgb(149, 123, 96)" mb="15px">
            SOCIAL MEDIA
          </CFView>
          <CFView row justifyStart alignCenter white bold>
            <CFLink href="https://www.facebook.com/pages/Asakusa/318447008276576">
              <CFImage
                w="40px"
                pr="10px"
                src={facebook}
                alt="Sushi Asakusa Facebook"
              />
            </CFLink>
            <CFLink href="https://www.yelp.ca/biz/asakusa-langley?osq=asakusa">
              <CFImage w="40px" pr="10px" src={yelp} alt="Sushi Asakusa Yelp" />
            </CFLink>
            <CFLink href="https://www.zomato.com/vancouver/asakusa-japanese-walnut-grove">
              <CFImage
                w="40px"
                pr="10px"
                src={zomato}
                alt="Sushi Asakusa Zomato"
              />
            </CFLink>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
